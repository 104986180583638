export const gql = `
query($slug: String){
  entry(
    section:"projects"
    slug: [$slug]
  ){
    id
    title
    ... on projects_projects_Entry{
      projectDescription
      projectRole
      projectTimeline
      projectSkillsets
      projectResources
      projectMainCategory{
        ... on projectCategories_Category{
          id
          title
        }
      }
      projectCategories{
        ... on projectCategories_Category{
          id
          title
        }
      }
      pageContent{
        ... on pageContent_textblock_BlockType{
          header
          leadText
          content:textBlock
          typeHandle
        }
        ... on pageContent_imageBlock_BlockType{
          blockSize
          typeHandle
          images {
            url
            ... on pages_Asset{
              uncroppedImage{
                src
                srcset
                srcWebp
                srcsetWebp
                placeholderSilhouette
                placeholderImage
                placeholderWidth
                placeholderHeight
                placeholderBox
                placeholderSvg
              }
            }
          }
        }
        ... on pageContent_columnBlock_BlockType{
          text
          columns
          textPosition
          typeHandle
        }
        ...on pageContent_youtubeEmbeddedVideo_BlockType{
          id:youtubeId
          videoName
          blockSize
          aspectRatio
          typeHandle
        }
      }
    }
    seomatic(asArray: true){
      metaTitleContainer
      metaLinkContainer
      metaScriptContainer
      metaJsonLdContainer
      metaTagContainer
    }
  }
}
`
