<template>
  <div id="project_template">
    <loader ref="loader" type="page"></loader>
    <div id="page-content" v-bind:class="[pageContentVisibilityState]">
      <div class="container">
        <!--[START] Header Block -->
        <project-header
          eyebrow="Project"
          v-bind:title="projectTitle"
          v-bind:subtitle="projectMainCategory"
        ></project-header>
        <!--[END] Block -->
        <!--[START] Section -->
        <section v-for="(block, key) in pageContent" v-bind:key="key">
          <!--[START] Block -->
          <project-image
            v-if="block.typeHandle==='imageBlock'"
            v-bind:images="block.images"
            v-bind:type="block.blockSize"
          ></project-image>
          <!--[END] Block -->
          <!--[START] Block -->
          <project-text
            v-if="block.typeHandle==='textblock'"
            v-bind:title="block.header"
            v-bind:lead="block.leadText"
            v-bind:content="block.content"
          ></project-text>
          <!--[END] Block -->
          <!--[START] Block -->
          <project-column-text
          v-if="block.typeHandle==='columnBlock'"
          v-bind:columns="block.columns"
          v-bind:content="block.text"
          v-bind:position="block.textPosition"
        ></project-column-text>
          <!--[END] Block -->
          <!--[START] Block-->
          <project-youtube
          v-if="block.typeHandle==='youtubeEmbeddedVideo'"
          v-bind:type="block.blockSize"
          v-bind:aspect-ratio="block.aspectRatio"
          v-bind:id="block.id"
          ></project-youtube>
          <!--[END] Block-->
          <pre style="background-color: black; color: white; padding:5px; font-size: 10px; display: none;">{{block}}</pre>
        </section>
        <!--[END] Section -->
      </div>
      <section class="additional-details">
        <div class="container">
          <!--[START] Skillset Item-->
          <div class="row text-center">
            <div class="col Nexa-Bold">
              <h2>Additional Details</h2>
            </div>
          </div>
          <div class="row text-left">
            <div class="col-lg-4">
              <strong class="NexaText-Bold">Role</strong><br />
              <label class="NexaText-Light">{{projectRole}}</label>
              <br />
              <strong class="NexaText-Bold">Timeline </strong><br />
              <label class="NexaText-Light">{{projectTimeline}}</label>
              <br />
              <strong class="NexaText-Bold">Category/Categories</strong><br />
              <ul>
                <li class="NexaText-Light" v-for="(category, key) in projectCategories" v-bind:key="key">{{category.title}}</li>
              </ul>
            </div>
            <div class="col-lg-4">
              <strong class="NexaText-Bold">Skillset(s) Applied</strong>
              <div class="details-content-container NexaText-Light" v-html="projectSkillsets"></div>
            </div>
            <div class="col-lg-4">
              <strong class="NexaText-Bold">Additional Information</strong>
              <div v-if="projectResources" class="NexaText-Light" v-html="projectResources"></div>
              <div v-else><label class="NexaText-LightItalic">No Additional Information</label></div>
            </div>
          </div>
          <!--[END] Skillset Item-->
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/Loader.vue'
// import ProjectHeader from '@/components/ProjectHeader.vue'
// import ProjectImage from '@/components/ProjectImage.vue'
// import ProjectText from '@/components/ProjectText.vue'
// import ProjectColumnText from '@/components/ProjectColumnText.vue'
// import ProjectYoutube from '@/components/ProjectYoutube.vue'
import { gql } from '@/gql/views/project.js'
import Api from '@/api.js'
import Seomatic from '@/seomatic.js'
const ProjectHeader = () => import(/* webpackChunkName: "project-header" */ '@/components/ProjectHeader.vue')
const ProjectImage = () => import(/* webpackChunkName: "project-image" */ '@/components/ProjectImage.vue')
const ProjectText = () => import(/* webpackChunkName: "project-text" */ '@/components/ProjectText.vue')
const ProjectColumnText = () => import(/* webpackChunkName: "project-column-text" */ '@/components/ProjectColumnText.vue')
const ProjectYoutube = () => import(/* webpackChunkName: "project-youtube" */ '@/components/ProjectYoutube.vue')

export default {
  name: 'Project',
  metaInfo () {
      return {
          title: this.metaData.title,
          meta: this.metaData.tags
      }
  },
  components: {
    ProjectHeader,
    ProjectImage,
    ProjectText,
    ProjectColumnText,
    ProjectYoutube,
    Loader
  },
  data () {
    return {
      projectTitle: '',
      projectRole: '',
      projectTimeline: '',
      projectSkillsets: '',
      projectResources: '',
      projectMainCategory: '',
      projectCategories: '',
      pageContent: [],
      pageContentVisibilityState: '',
      metaData: {}
    }
  },
  methods: {
    loadPage: function () {
      // Load the Page Function and Get Data
      var vue = this
      const api = new Api({
        callback: (response) => {
          // Response Gotten
          if (response.data.entry) {
            var entry = response.data.entry
            this.projectTitle = entry.title
            this.projectRole = entry.projectRole
            this.projectTimeline = entry.projectTimeline
            this.projectSkillsets = entry.projectSkillsets
            this.projectResources = entry.projectResources
            this.projectMainCategory = entry.projectMainCategory[0].title
            this.projectCategories = entry.projectCategories
            this.pageContent = entry.pageContent
            console.log(entry)
            const seomatic = new Seomatic()
            this.metaData = seomatic.setMetaData(entry.seomatic)
          }
          this.$router.onReady(() => {
            this.$refs.loader.stopAnimation()
            this.pageContentVisibilityState = 'active'
          })
        },
        query: gql,
        variables: {
          slug: vue.$route.params.projectSlug,
          preview: {
            token: this.$route.query.token
          }
        }
      })
      console.log(api)
    }
  },
  mounted () {
    this.$router.onReady(() => {
      this.$refs.loader.startAnimation()
      this.loadPage()
    })
  }
}
</script>
<style lang="scss">
  #project_template{
    .additional-details{
      background-color: #f0f0f0;
      padding-top: 30px;
      padding-bottom: 30px;
      h2{
        font-size: 20px;
        color: #666666;
        margin-bottom: 30px;
        text-transform: uppercase;
        letter-spacing: 1.0px;
      }
      ul{
        list-style: none;
        padding-left: 0px;
        list-style-type:none;
        li{
          list-style-type:none;
        }
      }
    }
  }
</style>
